import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { types as contributionActions } from '../../../actions/contributionActions';
import { types as contributionSummaryActions } from '../../../actions/contributionSummaryActions';
import { types as searchValueActions } from '../../../reducers/searchValueReducer';
import { BackButton } from '../../../components/common';
import ObservableContributionSummary from '../../../components/ObservableContributionSummary';
import {
  scrollToTop,
  itemDateBeforeLastFiling,
  listValidFilterObjects,
} from '../../../helpers/util';
import { sortDirections, statuses } from '../../../helpers/constants';
import {
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../../helpers/labelHelper';
import { useIntersect } from '../../../hooks/useIntersect';
import {
  getByYear,
  getCampaign,
  getContactType,
  getContributionLimitListCSVStatus,
  getContributionsSummaryList,
  getContributionsSummaryListStatus,
  getElectionCycle,
  getElectionYear,
  getListCSVStatus,
  getNameSearchValue,
  getUserSession,
} from '../../../selectors';
import { buildParams } from './helper';
import './index.css';
import { ConfirmContinueUpdateDialog } from '../../../components/Dialogs';
import { types as listActions } from '../../../actions/listActions';

export const ContributionLimits = ({ history }) => {
  const reduxDispatch = useDispatch();
  const contributionsSummaryFromRedux = useSelector(getContributionsSummaryList);
  const getContributionsSummaryFromReduxStatus = useSelector(getContributionsSummaryListStatus);
  const contributionCsvStatusFromRedux = useSelector(getListCSVStatus);
  const listCSVStatus = useSelector(getContributionLimitListCSVStatus);
  const filterText = useSelector(getNameSearchValue);
  const electionCycle = useSelector(getElectionCycle);
  const electionYear = useSelector(getElectionYear);
  const contactType = useSelector(getContactType);
  const byYear = useSelector(getByYear);
  const session = useSelector(getUserSession);
  const campaign = useSelector(getCampaign);
  const { lastFiledReportEndDate } = campaign;

  const [state, setState] = useState({
    skip: 0,
    limit: 100,
    sortBy: '',
    sortDir: sortDirections.NONE,
    confirmContinueUpdateHidden: true,
    continueEditMessageList: [],
    contributionsSummary: [],
    contributionsSummaryStatus: statuses.PROCESSING,
    editItemId: null,
    startDate: null,
    endDate: null,
  });
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const [backToTopButtonRef, scrollContainer] = useIntersect({});

  useEffect(() => {
    if (getContributionsSummaryFromReduxStatus === statuses.SUCCESS) {
      setState(state => ({
        ...state,
        contributionsSummary: contributionsSummaryFromRedux,
        contributionsSummaryStatus: statuses.SUCCESS,
      }));
    }
  }, [contributionsSummaryFromRedux, getContributionsSummaryFromReduxStatus]);

  useEffect(() => {
    scrollToTop();
    return () => {
      reduxDispatch({
        type: contributionActions.CLEAR_CONTRIBUTIONS,
      });
    };
  }, []);

  useEffect(() => {
    if (scrollContainer?.isIntersecting === true && showBackToTopButton) {
      setShowBackToTopButton(false);
    } else if (
      scrollContainer?.isIntersecting === false &&
      !showBackToTopButton &&
      state.contributionsSummary?.length > 100
    ) {
      setShowBackToTopButton(true);
    }
  }, [scrollContainer, state.contributionsSummary]);

  useEffect(() => {
    setState(state => ({
      ...state,
      contributionsSummary: [],
      contributionsSummaryStatus: statuses.PROCESSING,
    }));
    const contactTypeToEmpty = (contactType && contactType !== '0') ? contactType : '';
    const params = buildParams(state, filterText, electionCycle, electionYear, byYear, contactTypeToEmpty);
    reduxDispatch({
      type: contributionSummaryActions.GET_CONTRIBUTIONS_SUMMARY,
      data: { params },
    });
  }, [filterText, electionCycle, electionYear, contactType, byYear, state.sortBy, state.sortDir]);

  useEffect(() => {
    if (state.skip > 0) {
      const contactTypeToEmpty = (contactType && contactType !== '0') ? contactType : '';
      const params = buildParams(state, filterText, electionCycle, electionYear, byYear, contactTypeToEmpty);
      reduxDispatch({
        type: contributionSummaryActions.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE,
        data: { params },
      });
    }
  }, [state.skip]);

  const nextPage = () => {
    setState(state => ({
      ...state,
      skip: state.skip + 1,
    }));
  };

  const handleChange = fieldName => (e, val) => {
    const value = val.key !== undefined ? val.key : val;
    setState({
      ...state,
      [fieldName]: value,
      skip: 0,
    });
  };

  const setSort = ({ sortField, sortDirection }) => {
    setState({
      ...state,
      sortBy: sortField,
      sortDir: sortDirection,
      skip: 0,
    });
  };

  const edit = id => {
    history.push(`/filer/editContribution/${id}`);
  };

  const editClick = id => {
    const item = contributionsSummaryFromRedux.find(c => c._id === id);
    const { isItemFiled, reconciliationId, depositId, receivedDate } = (item || {});
    const isBadItemDate = itemDateBeforeLastFiling(receivedDate, lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'receipt',
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return setState(state => ({
        ...state,
        confirmContinueUpdateHidden: false,
        continueEditMessageList,
        editItemId: id,
      }));
    }

    edit(id);
  };

  const cancelContinueUpdate = () => {
    return setState(state => ({
      ...state,
      confirmContinueUpdateHidden: true,
      continueEditMessageList: [],
      editItemId: null,
    }));
  };

  const handleFilterChange = (e, entry) => {
    reduxDispatch({
      type: searchValueActions.SET_NAME_SEARCH,
      data: { nameSearchValue: entry },
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const changeSearchValue = fieldName => (evt, value) => {
    let payload = { fieldName, value: value.key || value };
    if (value?.key === 0) {
      payload = { fieldName, value: 0 };
    }
    reduxDispatch({
      type: searchValueActions.HANDLE_CHANGE,
      data: payload,
    });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const clearDates = () => {
    reduxDispatch({ type: searchValueActions.CLEAR_DATES });
    setState(state => ({
      ...state,
      skip: 0,
    }));
  };

  const exportToCSV = async () => {
    const filters = listValidFilterObjects({
      filterText,
      electionCycle: electionCycle.toString(),
      electionYear: electionYear.toString(),
      contactType: contactType.toString(),
    });
    reduxDispatch({
      type: listActions.GET_CONTRIBUTION_LIMIT_CSV,
      data: {
        filters: JSON.stringify(filters),
        byYear,
      },
    });
  };

  const exportToContributionCSV = async () => {
    const selected = state.contributionsSummary.filter(cs => cs.isSelected).map(cs => ({ contactId: cs.contactId, electionYear: cs.electionYear, electionCycle: cs.electionCycle, contactType: cs.contactType }));
    const filters = [
      {
        column: 'transactionType',
        expression: 'equals',
        value: 'contribution',
      },
      {
        column: 'transactionType',
        expression: 'exists',
        value: 'contribution',
      },
      {
        column: 'contributionSummaryFilter',
        expression: 'customFilter',
        value: selected,
      },
    ];

    reduxDispatch({
      type: listActions.GET_CSV,
      data: {
        fields: 'contributions-by-date-range-fields',
        filters: JSON.stringify(filters),
      },
    });

    setState(state => {
      const contributionsSummary = state.contributionsSummary.map(cs => {
        cs.isSelected = false;
        return cs;
      });
      return { ...state, contributionsSummary };
    });
  };

  const getOnCheck = (item) => {
    return (toss, value) => {
      setState(state => {
        const found = state.contributionsSummary.find(i => i._id === item._id);
        found.isSelected = value;
        return { ...state };
      });
    };
  };

  const actions = {
    changeSearchValue,
    clearDates,
    exportToContributionCSV,
    exportToCSV,
    handleChange,
    handleFilterChange,
    nextPage,
    setSort,
    getOnCheck,
  };

  if (listCSVStatus === statuses.PROCESSING) {
    return (
      <>
        <BackButton
          history={history}
          pageTitle="Contribution Summary"
        />
        <div className="ContributionLimits depth-1">
          <div className="list loading-csv">
            <Spinner size={SpinnerSize.large} label="Generating CSV" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <BackButton
        history={history}
        pageTitle="Contribution Summary"
      />
      <div className="ContributionLimits depth-1">
        <header className="contributions-header" ref={backToTopButtonRef} />
        <div className="view-all-contributions-content">
          <ObservableContributionSummary
            editItem={editClick}
            contributionsSummary={state.contributionsSummary}
            getContributionsSummaryStatus={state.contributionsSummaryStatus}
            contributionCsvStatus={contributionCsvStatusFromRedux}
            session={session}
            filterText={filterText}
            electionCycle={electionCycle}
            electionYear={electionYear}
            contactType={contactType}
            byYear={byYear}
            actions={actions}
            sortField={state.sortBy || 'updatedAt'}
            sortDirection={state.sortDir || sortDirections.DESC}
          />
        </div>
        <ConfirmContinueUpdateDialog
          dialogHidden={state.confirmContinueUpdateHidden}
          cancel={cancelContinueUpdate}
          confirm={() => edit(state.editItemId)}
          messageList={state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
        {showBackToTopButton && (
          <div className="back-to-top">
            <IconButton
              iconProps={{ iconName: 'ArrowUp' }}
              onClick={scrollToTop}
              title="Back to Top"
            />
          </div>
        )}
      </div>
    </>
  );
};

ContributionLimits.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ContributionLimits);
